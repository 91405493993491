import React from "react";
import SectionTitle from "../components/SectionTitle";

const ReturnPolicy = () => {
  return (
    <div className="container mx-auto px-2">
      <SectionTitle title={"Changes or returns Policy:"} />

      <div className="grid gap-5">
        <div className="grid gap-5">
          <span>
            ১. ডেলিভারি ম্যান সামনে থাকা অবস্থায় প্রোডাক্ট চেক করে নিন। যদি কোনও
            সমস্যা দেখতে পান তাহলে সাথে সাথেই আমাদের সাথে যোগাযোগ করুন। আমরা
            আপনার সমস্যা সমাধান করে দিবো।
          </span>
          <span>
            ২. ডেলিভারি ম্যান সামনে থাকা অবস্থায় যদি আপনি প্রোডাক্ট চেক করে না
            নিয়ে থাকেন সেক্ষেত্রে প্রোডাক্টে কোন সমস্যা থাকলে অথবা পরিবর্তন করতে
            চাইলে প্রোডাক্ট হাতে পাওয়ার ২৪ ঘণ্টার মধ্যে আমাদের জানাবেন। আমরা
            আপনার সমস্যা সমাধান করে দিবো। যদি সেই প্রোডাক্ট স্টকে না থাকে তাহলে
            অন্য প্রোডাক্ট নিতে পারবেন।
          </span>
          <span>
            ৩. ডেলিভারি ম্যান সামনে থাকা অবস্থায় যদি আপনার প্রোডাক্টটি পছন্দ না
            হয় বা নিতে না চান, তাহলে প্রোডাক্টটির ডেলিভারি চার্জ দিয়ে সাথে সাথেই
            রিটার্ন করে দিতে পারবেন।
          </span>
          <span>
            ৪. প্রোডাক্ট ডেলিভারি ও পেমেন্ট হওয়ার পর যদি আপনি কোন অবস্থাতেই
            প্রোডাক্টটি রাখতে না চান, সেক্ষেত্রে প্রোডাক্টটি ডেলিভারি হওয়ার ২৪
            ঘন্টার মধ্যে আমাদেরকে জানিয়ে প্রোডাক্টটি আমাদের ঠিকানায় ৭ দিনের
            মধ্যে পাঠাতে হবে। প্রোডাক্টটি চেক করার পর আপনার প্রোডাক্টটির
            সমপরিমাণ টাকা রিফান্ড করে দেওয়া হবে।
          </span>
          <span>
            Changes or returns Policy: Check the product while the delivery man
            is in front of you. If you see any problem then contact us
            immediately, and we will solve your problem. If you have not checked
            the product while the delivery man is in front of you, if there is
            any problem with the product, or if you want to change it, let us
            know within 24 hours of receiving the product, and we will solve
            your problem. If that product is not in stock then you can get
            another product. If you don’t like the product or don’t want to take
            it while the delivery man is in front of you, you can return the
            product immediately by paying the delivery charge. If you do not
            wish to keep the product in any condition after the product is
            delivered and paid for, you must notify us within 24 hours of the
            product being delivered and send the product to our address within 7
            days. After checking the product, you will be refunded the
            equivalent amount of the product.
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ReturnPolicy;
