import React from "react";
import SectionTitle from "../components/SectionTitle";

const SupportPolicy = () => {
  return (
    <div className="container mx-auto px-2">
      <SectionTitle title={"Refund policy"} />
      <div>
        <div className="grid gap-5">
          <span>Refund policy (in case of advance payment)</span>
          <span>
            If the ordered product is not in our stock, we will refund your paid
            price within 7 days. If SOMBHAAR.COM is unable or unwilling to serve
            any product, we will refund your refund within the stipulated time.
          </span>
          <span>
            If the customer returns any paid product, we will refund the
            customer's money as per the terms of our product return policy.
          </span>
          <span>
            If you find that your product is problematic, SOMBHAAR.COM will
            refund your paid price if you return it according to our product
            return policy.
          </span>
          <span>
            If a product is delivered to you that you did not order,
            SOMBHAAR.COM will change the product you ordered or refund the price
            paid.
          </span>
          <span>
            SOMBHAAR.COM will re-inform you about your order before shipping the
            product. You cannot change your order after shipment.
          </span>
          <span>
            No price will be refunded except for the only problematic product
            after delivery. If the customer receives a damaged / damaged /
            problematic product or finds a product that does not match his / her
            order, you must notify him / her within 48 hours of receiving the
            product by mail to info@sombhaar.com or by calling +8801729000258.
            In this case, if you are not interested in getting a similar good
            product instead of the problematic product, you will get a refund of
            the paid price of your product.
          </span>
          <span>
            After receiving any objection or complaint about the product,
            SOMBHAAR.COM will investigate the complaint and if your complaint is
            true, it will take necessary steps to refund the paid price of your
            product.
          </span>
          <span>
            The price refund process will start after your refundable product
            reaches us. Upon receipt of the refundable product we verify the
            quality of the product, the manner in which you paid the price at
            the time of payment of the product i.e. by card or account will be
            paid to your refundable product within 7 days within our stipulated
            time.
          </span>
          <span>
            In case of refund of the price of the product only the amount equal
            to the price of the product will be refunded. In this case no bonus,
            gift price or benefits, no refund of delivery charge.
          </span>
          <span>
            You can purchase any product from SOMBHAAR.COM with a refund for the
            price of your refundable product.
          </span>
          <span>
            Follow this procedure to get a refund for your product. Go to
            product price return box or page. Click the return amount button or
            box. Confirm the return by following the screen. If the payment for
            your product is in cash at the time of purchase, then we are through
            development or if your payment is through card or account. In this
            case, fill in the details of your account accurately. You will
            receive a refund for your product in your account.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SupportPolicy;
