import Banner from "../components/Banner";

import CategoryCard from "../components/CategoryCard";

import BannerSlider from "./BannerSlider";
import BestSelling from "./BestSelling";
import FeaturedProduct from "./FeaturedProduct";

const Home = () => {
  return (
    <div className=" p-4 md:p-0">
      <BannerSlider />
      <CategoryCard />
      <BestSelling />
      <Banner />
      <FeaturedProduct />
    </div>
  );
};

export default Home;
