import React from "react";
import SectionTitle from "../components/SectionTitle";

const PrivacyPolicy = () => {
  return (
    <div className="wrapper">
      <SectionTitle title={"Cancellation Policy"} />
      <div>
        <div className="grid gap-5">
          <span>Cancellation Policy</span>
          <span>
            SOMBHAAR.COM aims to provide 100% customer satisfaction. If for any
            reason you want to cancel the order, we will assist you. Upon
            successful cancellation of your purchased product, you will receive
            a refund in accordance with our product refund policy.{" "}
          </span>
          <span>
            You can cancel the product order before your purchased product is
            delivered. If the delivery of the product is completed, there will
            be no chance of canceling the order of the product.
          </span>
          <span>
            If a product of an order is also delivered, you cannot cancel the
            order. You must contact us within 12 hours to cancel your order.
          </span>
          <span>
            SOMBHAAR.COM reserves the right to cancel your order at any time due
            to unavoidable reasons even after order confirmation. In this case,
            if you have paid the advance price, SOMBHAAR.COM will refund your
            advance payment according to the price refund policy.
          </span>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
