import { Link, NavLink, useNavigate } from "react-router-dom";

import { BsCart3 } from "react-icons/bs";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  // ListItemSuffix,
  // Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
// import {
//   PresentationChartBarIcon,
//   ShoppingBagIcon,
//   UserCircleIcon,
//   Cog6ToothIcon,
//   InboxIcon,
//   PowerIcon,
// } from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

import { BiLeftArrowAlt } from "react-icons/bi";

import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import { RxCross1 } from "react-icons/rx";

const SideNav = ({ handleClose }) => {
  // const navigate = useNavigate();

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#083344" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const [open, setOpen] = useState("");

  const handleOpen = (value) => {
    setOpen(open === value ? "" : value);
  };
  const { items: categories } = useSelector((state) => state.category);
  // const { items: subcategories } = useSelector((state) => state.subCategor);
  // const getSubcategoriesByCategoryId = (categoryId) => {
  //   return subcategories.filter(
  //     (subcategory) => +subcategory.category_id === categoryId
  //   );
  // };

  // const goToCategaryPage = () => {};
  //
  return (
    <Card className=" filterSideNav-wrapper rounded-none   h-100vh z-[999999] w-full max-w-[18rem]  overflow-y-scroll fixed  left-0 bottom-0 top-0  shadow-xl shadow-blue-gray-900/5">
      {/* <div className="">
        <Typography variant="h5" color="blue-gray">
          <BiLeftArrowAlt onClick={handleClose} className="text-[1.5rem]" />
        </Typography>
      </div> */}
      <List>
        <div className="grid grid-cols-[85%_15%] text-2xl  bg-green-400  mb-4 w-full">
          <div
            className=" bg-[#E50014] text-white py-2 flex items-center justify-center"
            l
          >
            Categories
          </div>
          <div
            className="bg-black text-white py-2 flex items-center justify-center"
            l
          >
            <button
              onClick={handleClose}
              className="my-2 flex items-end justify-end"
            >
              <RxCross1 />
            </button>
          </div>
        </div>
        {categories?.map((category, index) => (
          <Accordion
            key={index}
            open={open === index}
            icon={
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4  transition-transform ${
                  open === index ? "rotate-180" : ""
                }`}
              />
            }
            className=" border-b-2"
          >
            <ListItem className="p-0" selected={open === index}>
              <div onClick={() => handleOpen(index)} className="p-3">
                {/* <ListItemPrefix>
                    <PresentationChartBarIcon className="h-5 w-5" />
                  </ListItemPrefix> */}
                <Link
                  onClick={handleClose}
                  to={`/categorypage/${category.id}`}
                  color="blue-gray"
                  className="mr-auto font-normal hover:text-[#BB2121] "
                >
                  <span>{category.name}</span>
                </Link>
              </div>
            </ListItem>
            {/* <AccordionBody className="py-1">
              <List className="p-0">
                {getSubcategoriesByCategoryId(category.id).map(
                  (subcategory, subIndex) => (
                    <Link key={subIndex} to={`/subcategory/${subcategory.id}`}>
                      <ListItem onClick={handleClose}>
                        <ListItemPrefix>
                          <ChevronRightIcon
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>

                        {subcategory.name}
                      </ListItem>
                    </Link>
                  )
                )}
              </List>
            </AccordionBody> */}
          </Accordion>
        ))}
      </List>
    </Card>
  );
};

export default SideNav;
